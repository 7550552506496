<template>
  <div class="recharge-page">
    <div class="nav">
      <div :class="isActive == 0 ? 'item active' : 'item'" @click="$router.replace('/recharge')" >流量套餐</div>
      <div :class="isActive == 1 ? 'item active' : 'item'" @click="$router.replace('/recharge/package')">流量包</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'recharge',
  data () {
    return {
      isActive: 0
    }
  },
  created () {
    this.changeNav(this.$route.name)
    document.title = this.$route.meta.title
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    changeNav (name) {
      switch (name) {
        case 'recharge':
          this.isActive = 0
          break
        case 'package':
          this.isActive = 1
          break
        default:
          this.isActive = 0
          break
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.changeNav(to.name)
    document.title = this.$route.meta.title
    next()
  },
  activated () {
    this.changeNav(this.$route.name)
    document.title = this.$route.meta.title
  }
}
</script>

<style lang="scss" scoped>
  .recharge-page{
    padding-top: 2.875rem;
    padding-bottom:1.25rem;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #E7F2FA;
  }
  .nav{
    height: 2.875rem;
    line-height: 2.875rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
    position: fixed;
    left: 0;
    top:0;
    z-index: 50;
    .item{
      flex: 1;
      position: relative;
      &::after{
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color:  #0173F7;
      }
      &.active{
        &::after{
          content: '';
        }
      }
    }
  }
</style>
